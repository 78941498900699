import * as Sentry from '@sentry/gatsby';
import './src/styles/global.css';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://ddb50d54c96e49e0a2a319dcdb5c8c76@o4505228601065472.ingest.sentry.io/4505228610764800',
    environment: process.env.NODE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/api\.tailplane\.com/],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
